import './Header.css';

export default function Header() {
  return (
    <header>
      <h1>Simon Foldvik</h1>
      <p>👨🏻‍🎓 Aker Scholar in mathematics at Balliol College, Oxford.</p>
      <p>👨🏼‍💻 Senior software engineer with three years of industry experience at one of Norway's leading IT consulting firms.</p>
      <p>✉️ Email in base64: c2ltb25mb2xkdmlrQHByb3Rvbm1haWwuY29tCg==</p>
      <section className="short-cv">
        <h2>Short CV</h2>
        <ul>
          <li>MSc in Mathematical Sciences (University of Oxford, 2024-2025)</li>
          <li>Aker Scholar 2024: Full funding for studies at Oxford</li>
          <li>Senior Software Engineer (Bekk Consulting)</li>
          <li>Research Internship (Simula Research Laboratories)</li>
          <li>Graduate Teaching Assistant in Mathematics (University of Oslo)</li>
          <li>MSc in Mathematics (University of Oslo)</li>
          <li>BSc in Mathematics and Computer Science (University of Oslo)</li>
        </ul>
      </section>
    </header>
  );
}
